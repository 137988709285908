<template>
  <list-item
    :data="{ ...data, BorderWidth: 0, Type: 'card' }"
  >
    <template #text>
      <div class="w-full">
        <p class="mb-4">{{ data.Text }}</p>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="(d, i) in data.Data" :key="i">
            <div class="relative">
              <b-tag class="absolute" v-if="d.Badge" type="is-danger" rounded style="top: -10px; right: 10px;z-index: 10">{{d.Badge}}</b-tag>
              <div class="flex flex-col md:flex-row items-center">
                <div class="md:mr-4">
                  <icon-image :value="d.Icon"></icon-image>
                </div>
                <router-link :to="d.To" class="text-gray-900 hover:text-blue-500">
                  <div class="mb-2 text-center md:text-left" v-html="d.Text" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </list-item>
</template>

<script>
import IconImage from '../common/IconImage'
import ListItem from '../ListItem'
export default {
  components: {
    ListItem,
    IconImage
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style>

</style>
